@charset "UTF-8";
/* -------------------------------------
 * designCommon.css
 *
 * 共通スタイルを書いておく
------------------------------------- */

/* -----------------------------
 * Color Palette
 * 基本的に以下カラーのみ使用
 ------------------------------*/
/*
--unnamed-color-009D2A: #009D2A;
--unnamed-color-ffffff: #FFFFFF;
--unnamed-color-E2E6EB: #E2E6EB;
--unnamed-color-E1E2E1: #F0EEEF;
--unnamed-color-2ea0b4: #2EA0B4;
--unnamed-color-ea0000: #EA0000;
--unnamed-color-F5F5F6: #F5F5F6;
--unnamed-color-8B8B8B: #8B8B8B;
--unnamed-color-333333: #333333;
--unnamed-color-9D000A: #9D000A;
--unnamed-color-001338: #001338;
--unnamed-color-436390: #436390;
--unnamed-color-D70A30: #D70A30;
--unnamed-color-0E3962: #0E3962;
*/

/* -----------------------------
 * ページ共通スタイル
 * ページをまたいで利用
 ------------------------------*/
body {
  background: #ffffff;
  font-family: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro',
    'ＭＳ Ｐゴシック', 'Arial', sans-serif;
  min-width: 1024px;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}
#MainBody {
  flex: 1;
}
/* HEADER NAVIGATION
---------------------- */
.headerBlock {
  /* header */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background: #ffffff;
  z-index: 100;
}
.headerInner {
  /* div */
  display: flex;
  height: 56px;
  /* min-width: 1024px; */
  justify-content: space-between;
}

/* 左側メニュー */
.headerMainMenuBlock {
  display: flex;
}
.headerLogoBlock {
  display: flex;
  margin: 0 auto 0 32px;
  line-height: 56px;
}
.headerLogoLink {
  display: block;
  text-decoration: none;
}
.headerOrixLogoImage {
  margin-left: 8px;
}
.headerLogoImage {
  vertical-align: top;
  margin-top: 8px;
}
.headerLogoCopyPara {
  margin-left: 16px;
  font-size: 12px;
  color: #2a385f;
}

.headerMainMenuList {
  display: flex;
  text-align: center;
}
.headerMainMenuListItemSingle {
  width: 142px;
}
.headerMainMenuListItemMulti {
  width: 178px;
}
.headerMainMenuListItemSingleLink {
  display: block;
  width: 100%;
  height: 56px;
  padding-left: 1em;
  line-height: 56px;
  text-decoration: none;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  background: url(../image/common/icon_list_blue.svg) no-repeat 10% center;
  box-sizing: border-box;
  border-bottom: 2px solid #ffffff;
}
.headerMainMenuListItemSingleLink:hover {
  background-color: #e6eef8;
  border-bottom: 2px solid #1c6ecd;
}
.headerMainMenuListItemMultiLink {
  display: block;
  width: 100%;
  height: 56px;
  padding-left: 1em;
  line-height: 56px;
  text-decoration: none;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
  background: url(../image/common/icon_list_blue.svg) no-repeat 8% center;
  box-sizing: border-box;
  border-bottom: 2px solid #ffffff;
}
.headerMainMenuListItemMultiLink:hover {
  background-color: #e6eef8;
  border-bottom: 2px solid #1c6ecd;
}

/* 右側メニュー */
.headerSubMenuBlock {
  display: flex;
}
.headerSubMenuLinkFaq,
.headerSubMenuLinkFaqNoLogin {
  width: 120px;
  height: 56px;
  display: block;
  color: #333333;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  background: url(../image/common/icon_faq.svg) no-repeat center 8px;
  line-height: 75px;
  box-sizing: border-box;
}
.headerSubMenuLinkFaq:hover,
.headerSubMenuLinkFaqNoLogin:hover {
  background: #e6eef8 url(../image/common/icon_faq_hover.svg) no-repeat center 8px;
  border-bottom: 2px solid #1c6ecd;
}
.headerSubMenuLinkSignUpBlock {
  position: relative;
  padding: 0 12px 0 12px;
}
.headerSubMenuLinkUserNameBlock {
  position: relative;
  padding: 0 12px 0 12px;
}
.headerSubMenuLinkUserNameBlockDefalt {
  background: url(../image/common/icon_arrow_down_white.png) no-repeat 90% center;
  background-color: #1c6ecd;
}
.headerSubMenuLinkUserNameBlockActive {
  background: url(../image/common/icon_arrow_up_white.png) no-repeat 90% center;
  background-color: #1c6ecd;
}
.headerSubMenuLinkUserNamePara {
  padding-left: 32px;
  height: 56px;
  font-size: 14px;
  color: #ffffff;
  line-height: 56px;
  background: url(../image/common/icon_user.svg) no-repeat center left;
}
.headerSubMenuLinkUserNameSpan {
  display: inline-block;
  width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.headerSubMenuUserNameList {
  width: 100%;
  position: absolute;
  top: 56px;
  left: 0;
  border: 1px solid #e0e0e0;
  background: #ffffff;
}
.headerSubMenuUserNameListItem {
  height: 38px;
  line-height: 38px;
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.5s ease-out;
}
.headerSubMenuUserNameListItem:hover {
  background-color: #e6eef8;
}
.headerSubMenuUserNameLink {
  width: 100%;
  display: block;
  font-size: 14px;
  text-decoration: none;
  color: #333333;
  text-align: center;
}
.headerSubMenuUserNameLinkImage {
  margin-left: 8px;
}
.headerSubMenuLinkChatBlock {
  position: fixed;
  bottom: 80px;
  right: 16px;
}
.headerSubMenuLinkChat {
  display: block;
  width: 90px;
  height: 90px;
  font-size: 12px;
  font-weight: bold;
  background: url(../image/common/icon_chat.png) no-repeat center 16px;
  border-radius: 90px;
  background-color: #00b862;
  border: 2px solid #00b862;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding-top: 36px;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.headerSubMenuLinkChat:hover {
  background-color: #ffffff;
  color: #00b862;
  background: url(../image/common/icon_chat_green.png) no-repeat center 16px;
}
.headerSubMenuLinkSignUp {
  height: 56px;
  font-size: 14px;
  color: #ffffff;
  line-height: 56px;
  text-align: center;
}
.headerSubMenuLinkLogin {
  height: 56px;
  font-size: 14px;
  color: #ffffff;
  line-height: 56px;
  background: url('../image/common/icon_login.svg') no-repeat center left 6%;
  text-align: center;
}
.headerSubMenuLinkSignUpBlock {
  background-color: #ff8f22;
}
.headerSubMenuLinkUserNameBlockLogin {
  background-color: #1c6ecd;
}
.headerSubMenuSignUpSpan {
  display: inline-block;
  width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  padding: 0 8px;
}
.headerSubMenuLoginSpan {
  display: inline-block;
  width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 16px;
  font-weight: bold;
}
.headerSubMenuLinkLogin a,
.headerSubMenuLinkSignUp a {
  color: #ffffff;
}
/* 型番仕様確定フローチャート
---------------------- */
.specFlowBlock {
  width: 100%;
  height: 38px;
  padding-top: 56px;
  background: #eceeef;
  position: sticky;
  top: 0;
  z-index: 90;
}

.specFlowInner {
  display: flex;
  width: 944px;
  margin: 0 auto 0;
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .specFlowInner {
    width: 944px;
  }
}

@media screen and (min-width: 1301px) {
  .specFlowInner {
    width: 1200px;
  }
}

.specFlowList {
  height: 38px;
  overflow: hidden;
  display: flex;
  line-height: 38px;
  font-size: 13px;
  width: 944px;
}

.specFlowListItem {
  width: 208px;
  background: #eceeef;
  text-align: center;
  color: #8b8b8b;
  position: relative;
}

li.specFlowListItem::before {
  content: '';
  position: absolute;
  top: 5px;
  right: -14px;
  background: #eceeef;
  border: none;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  width: 26px;
  height: 26px;
  transform: rotate(45deg);
  z-index: 10;
}

/*フローバーアクティブ*/
.specFlowListItemActive {
  width: 208px;
  background: #1c6ecd;
  text-align: center;
  color: #fff;
  position: relative;
  font-weight: bold;
}

li.specFlowListItemActive::before {
  content: '';
  position: absolute;
  top: 6px;
  right: -13px;
  background: #1c6ecd;
  border: none;
  width: 26px;
  height: 26px;
  transform: rotate(45deg);
  z-index: 10;
}

.specFlowListItemActiveNavigate {
  cursor: pointer;
  background: #ffffff;
  color: #8b8b8b;
  border: 2px solid #1c6ecd;
  line-height: 34px;
  font-weight: normal;
}

li.specFlowListItemActiveNavigate::before {
  content: '';
  position: absolute;
  top: 4px;
  right: -15px;
  background: #ffffff;
  border: none;
  border-top: 2px solid #1c6ecd;
  border-right: 2px solid #1c6ecd;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
  z-index: 10;
}

/* MAIN CONTENTS
---------------------- */
.mainContentsBlock {
  width: 944px;
  margin: 52px auto 120px;
  max-width: 1300px;
  min-width: 944px;
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .mainContentsBlock {
    width: 944px;
  }
}
@media screen and (min-width: 1301px) {
  .mainContentsBlock {
    width: 1300px;
  }
}

/* PAGE TITLE
---------------------- */
.pageTitleBlock {
}
.pageTitleInner {
  display: flex;
  justify-content: space-between;
}
.pageTitle {
  color: #333;
  font-size: 32px;
  font-weight: bold;
}
/* ボタンを含む場合 */
.pageTitleWithBtn {
  display: flex;
}
.pageTitle + a {
  margin-left: 16px;
}
.pageTitleWithBtn .btnCirclePlusOrange,
.pageTitleWithBtn .btnChatWhite {
  margin-right: 8px;
  margin-top: 2px;
}

/* LIST CONTROLL
---------------------- */
.modelNumberListControlBlock {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.trialCalcSpecListControlBlock {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
/* 一覧の絞り込みリンク */
.modelNumberListControlInner {
}
.modelNumberListNarrowingList {
  display: flex;
  list-style: none;
  margin-top: 20px;
}
.modelNumberListNarrowingListItem {
  height: 14px;
  padding: 0 9px;
  line-height: 14px;
  border-right: 1px solid #8b8b8b;
}
.modelNumberListNarrowingListItem:first-child {
  padding: 0 12px 0 0;
}
.modelNumberListNarrowingListItem:last-child {
  border-right: none;
}
.modelNumberListNarrowingListLink {
  font-size: 13px;
  text-decoration: none;
  color: #1c6ecd;
}
.modelNumberListNarrowingListItemCount {
  font-size: 12px;
  color: #8b8b8b;
}
.modelNumberListNarrowingListItemActive {
  font-weight: bold;
}

/* ページング */
.modelNumberListPagingInner {
  display: flex;
}
.modelNumberListPagingBlock {
  display: flex;
  margin: 12px 0;
  font-size: 13px;
  color: #333333;
}
.modelNumberListPagingPara {
  margin: 0 0 12px 24px;
}

.modelNumberListPagingLinkPrevDisabled,
.modelNumberListPagingLinkPrevActive,
.modelNumberListPagingLinkNextDisabled,
.modelNumberListPagingLinkNextActive {
  display: inline-block;
  width: 16px;
  height: 16px;
}
.modelNumberListPagingLinkPrevDisabled,
.modelNumberListPagingLinkPrevActive {
  margin: 0 8px;
}
.modelNumberListPagingLinkNextDisabled,
.modelNumberListPagingLinkNextActive {
  margin: 0 0 0 8px;
}

.modelNumberListPagingLinkPrevDisabled {
  background: url(../image/common/btn_page_prev_disabled.svg) no-repeat top left;
}
.modelNumberListPagingLinkPrevActive {
  background: url(../image/common/btn_page_prev_active.svg) no-repeat top left;
}
.modelNumberListPagingLinkNextDisabled {
  background: url(../image/common/btn_page_next_disabled.svg) no-repeat top left;
}
.modelNumberListPagingLinkNextActive {
  background: url(../image/common/btn_page_next_active.svg) no-repeat top left;
}

.modelNumberListPagingLinkPrevActive:hover,
.modelNumberListPagingLinkNextActive:hover {
  opacity: 0.7;
}

/* 件数・試算・削除　固定コントロール */
.fixedListControlBlock {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px;
  background: #fff;
  border-top: 1px solid #eceeef;
}
.fixedListControlInner {
  display: flex;
}
.fixedListControlInner {
  width: 944px;
  margin: 10px auto 0px;
  max-width: 1200px;
  min-width: 944px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .fixedListControlInner {
    width: 944px;
  }
}
@media screen and (min-width: 1301px) {
  .fixedListControlInner {
    width: 1200px;
  }
}
.fixedListControlSelectCountPara {
  margin-right: 16px;
  line-height: 40px;
  font-size: 14px;
  color: #333333;
}
.fixedListControlSelectCountPara span {
  margin: 0 4px;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Arial';
}
.fixedListControlBlock .btnOrangeCommit {
  margin-right: 8px;
}
.fixedListControlBlock .btnWhiteBaseBlue {
  margin-right: 8px;
}

/*  FOOTER NAVIGATION
---------------------- */
.footerBlock {
  /* footer */
  width: 100%;
  /* height: 40px; */
  box-sizing: border-box;
}
.footerNoUi {
  margin: 60px auto 20px;
}
.footerHasControlUi {
  /* margin: 80px auto; */
}
.footerInner {
  /* div */
  display: flex;
  min-width: 960px;
  padding: 0 32px;
}
.footerLogoBlock {
  margin-right: 32px;
}
.footerLogoLink {
  text-decoration: none;
}
.footerMenuBlock {
  margin-top: 16px;
}
.footerMenuList {
  display: flex;
}
.footerMenuListItem {
  padding-right: 1em;
}
.footerMenuListItemLink {
  font-size: 12px;
  color: #8b8b8b;
  text-decoration: none;
  cursor: pointer;
}
.footerAddress {
  font-size: 9px;
  color: #8b8b8b;
  font-style: normal;
  line-height: 24px;
}
@media screen and (max-width: 768px) {
  .footerLogoBlock {
    margin-right: 0px;
  }
  .footerMenuListItem {
    padding-right: 0px;
    height: 40px;
    width: 200px;
    text-align: center;
  }
  .footerMenuListItemLink {
    display: inline-block;
    text-align: center;
    width: 100%;
    border-radius: 35px;
    text-decoration: none;
    border: 1px solid #d4d4d4;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0.04em;
    padding: 5px;
    margin-bottom: 8px;
  }
  .footerMenuListItemLink:hover {
    color: #1c6ecd;
  }
}

/* COMMON PARTS -- Button
---------------------- */
.searchInputBlock {
  position: relative;
}
.searchfKeywordInput {
  width: 200px;
  padding: 8px 24px 8px 8px;
  font-size: 13px;
  border: 1px solid #f0eeef;
  background-color: #ffffff;
}
.searchfKeywordSubmit {
  position: absolute;
  right: 0;
  display: inline-block;
  width: 40px;
  height: 38px;
  background: none;
  border: none;
}
.btnCirclePlusBlue {
  display: inline-block;
  height: 36px;
  padding: 8px 12px 8px 36px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  border-radius: 4px;
  text-decoration: none;
  background: url(../image/common/icon_circle_plus_white.svg) no-repeat 10% 50%;
  background-color: #0e3962;
  box-sizing: border-box;
}
.btnCirclePlusOrange {
  display: inline-block;
  height: 36px;
  padding: 6px 12px 8px 36px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  border-radius: 36px;
  text-decoration: none;
  background: url(../image/common/icon_circle_plus_white.svg) no-repeat 8% 50%;
  background-color: #ff8f22;
  box-sizing: border-box;
  border: 2px solid #ff8f22;
  transition: all 0.5s ease-out;
}
.btnCirclePlusOrange:hover {
  color: #ff8f22;
  background: url(../image/common/icon_circle_plus_orange.svg) no-repeat 8% 50%;
  background-color: #ffffff;
  border: 2px solid #ff8f22;
}

.btnChatWhite {
  display: inline-block;
  height: 36px;
  padding: 8px 12px 8px 36px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: #1c6ecd;
  border-radius: 36px;
  border: 2px solid #1c6ecd;
  background: url(../image/common/icon_chat_blue.svg) no-repeat 7% 50%;
  background-color: #ffffff;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnChatWhite:hover {
  background: url(../image/common/icon_chat.svg) no-repeat 7% 50%;
  background-color: #1c6ecd;
  color: #ffffff;
}
.btnOrangeCommit {
  display: inline-block;
  padding: 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 24px;
  border: none;
  background-color: #ff8f22;
  border: 2px solid #ff8f22;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnOrangeCommit:hover {
  color: #ff8f22;
  background-color: #ffffff;
}
.btnOrangeCommit:disabled {
  background-color: #f0eeef;
  color: #8b8b8b;
  border: 2px solid #f0eeef;
}
.btnOrangeCommitBig {
  height: auto;
  border-radius: 48px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  line-height: 16px;
  background-color: #ff8f22 !important;
  border: 2px solid #ff8f22 !important;
  color: #fff !important;
}
.btnOrangeCommitBigHoverEnable:hover {
  color: #ff8f22 !important;
  background-color: #ffffff !important;
}
.btnOrangeCommitTable {
  margin-top: 12px;
  width: 250px;
  height: 56px;
  line-height: 20px;
}

.btnOrangeCommitWhite {
  display: inline-block;
  padding: 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #ff8f22;
  border-radius: 24px;
  border: none;
  background-color: #ffffff;
  border: 2px solid #ff8f22;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnOrangeCommitWhite_molding {
  display: inline-block;
  padding: 8px 30px 8px 30px;
  height: 80px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #ff8f22;
  border-radius: 24px;
  border: none;
  background-color: #ffffff;
  border: 2px solid #ff8f22;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnOrangeCommitWhite:hover {
  color: #ffffff;
  background-color: #ff8f22;
}
.btnOrangeCommitWhite:disabled {
  background-color: #f0eeef;
  color: #8b8b8b;
  border: 2px solid #f0eeef;
}
.btnOrangeCommitWhite_molding:hover {
  color: #ffffff;
  background-color: #ff8f22;
}
.btnOrangeCommitWhite_molding:disabled {
  background-color: #f0eeef;
  color: #8b8b8b;
  border: 2px solid #f0eeef;
}

.btnGrayDelete {
  display: inline-block;
  padding: 8px 20px 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #636d79;
  border-radius: 24px;
  border: none;
  border: 2px solid #636d79;
  box-sizing: border-box;
  background-color: #ffffff;
  background: url(../image/common/icon_delete_gray.svg) no-repeat 25% 50%;
  transition: all 0.5s ease-out;
}
.btnGrayDelete:hover {
  color: #fff;
  background: url(../image/common/icon_delete_white.svg) no-repeat 25% 50%;
  background-color: #636d79;
}
.btnGrayDelete:disabled {
  color: #8b8b8b;
  border: 2px solid #f0eeef;
  background: url(../image/common/icon_delete_gray.svg) no-repeat 25% 50%;
  background-color: #f0eeef;
}

.btnOrangeEdit {
  display: inline-block;
  padding: 8px 20px 8px 40px;
  height: 60px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 24px;
  border: none;
  background: url(../image/common/icon_edit_white.svg) no-repeat 15% 50%;
  background-color: #ff8f22;
  border: 2px solid #ff8f22;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnOrangeEdit:hover {
  color: #ff8f22;
  background: url(../image/common/icon_edit_orange.svg) no-repeat 15% 50%;
  background-color: #ffffff;
}
.btnOrangeEdit:disabled {
  color: #8b8b8b;
  border: 2px solid #f0eeef;
  background: url(../image/common/icon_edit_gray.svg) no-repeat 15% 50%;
  background-color: #f0eeef;
}
.btnWhiteBaseBlue {
  display: inline-block;
  padding: 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #1c6ecd;
  border-radius: 24px;
  border: none;
  background-color: #ffffff;
  border: 2px solid #1c6ecd;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}

.btnWhiteBaseBlue:hover {
  background-color: #1c6ecd;
  color: #ffffff;
}
.btnWhiteBaseBlue:disabled {
  color: #8b8b8b;
  border: 2px solid #f0eeef;
  background-color: #f0eeef;
}
.btnWhiteBaseBlueCompare {
  display: inline-block;
  padding: 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #1c6ecd;
  border-radius: 4px;
  border: none;
  background: url(../image/common/icon_compare_blue.svg) no-repeat 85% 50%;
  background-color: #ffffff;
  border: 2px solid #1c6ecd;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnWhiteBaseBlueCompare:hover {
  background: url(../image/common/icon_compare_white.svg) no-repeat 85% 50%;
  background-color: #1c6ecd;
  color: #ffffff;
}
.btnOrangeTrialCalc {
  display: block;
  padding: 8px 20px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  border-radius: 24px;
  border: none;
  background-color: #ff8f22;
  border: 2px solid #ff8f22;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  float: right;
}
.btnOrangeTrialCalc:hover {
  color: #ff8f22;
  background-color: #ffffff;
}
.btnOrangeTrialCalc:disabled {
  background-color: #f0eeef;
  color: #8b8b8b;
  border: 2px solid #f0eeef;
}
.btnWhiteBaseGray {
  display: inline-block;
  padding: 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #8b8b8b;
  border-radius: 24px;
  border: none;
  background-color: #ffffff;
  border: 2px solid #8b8b8b;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnWhiteBaseGray:hover {
  background-color: #8b8b8b;
  color: #ffffff;
}
.btnBlue {
  display: inline-block;
  padding: 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  border-radius: 24px;
  border: none;
  background-color: #1c6ecd;
  border: 2px solid #1c6ecd;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
}
.btnBlue:hover {
  background-color: #ffffff;
  color: #1c6ecd;
}

/* COMMON PARTS -- Form Parts
---------------------- */
label {
  position: relative;
  word-break: break-all;
}
input {
  display: inline-block;
  padding: 8px;
  font-size: 14px;
  color: #333333;
  border: 1px solid #dfe1e3;
  border-radius: 2px;
}
textarea {
  padding: 8px;
  border: 1px solid #dfe1e3;
  font-size: 13px;
  resize: none;
}
select {
  padding: 0 8px;
  border: 1px solid #f0eeef;
  border-radius: 2px;
}
select:disabled {
  background: #f0eeef;
}

/* ヘルプアイコン */
.formHelpInfomationLink {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(../image/common/icon_help_blue.svg) no-repeat left top;
  margin-left: 4px;
}
.formHelpInfomationDetail {
  position: absolute;
  right: -200px;
  top: 0;
  width: 200px;
  padding: 8px;
  background: #1c6ecd;
  border-radius: 3px;
  opacity: 0.9;
}
.formHelpInfomationDetailVisible {
  visibility: visible;
}
.formHelpInfomationDetailHidden {
  visibility: hidden;
}
.formHelpInfomationDetailPara {
  color: #ffffff;
  font-size: 12px;
  line-height: 1.4em;
}

/* MODAL WINDOW
---------------------- */
.modalWindowWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(51, 51, 51, 0.8);
  z-index: 100;
}
.modalWindowBlock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  background: #ffffff;
}
.modalWindowInner {
}
.modalWindowHasHeader {
}
.modalWindowHeader {
  position: relative;
  height: 64px;
  background: #1c6ecd;
  text-align: center;
}
.modalWindowNoTitleHeader .modalWindowHeader {
  background: #ffffff;
}
.modalWindowHeaderTitle {
  line-height: 64px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}
.modalWindowHeaderCloseLink {
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
  height: 64px;
  padding-top: 36px;
  display: block;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background: url(../image/common/icon_cross_white.svg) no-repeat 50% 38%;
  box-sizing: border-box;
}
.modalWindowHeaderCloseChatLink {
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
  height: 64px;
  padding-top: 36px;
  display: block;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background: url(../image/common/icon_cross_white.svg) no-repeat 50% 38%;
  background-color: #00b862;
  box-sizing: border-box;
}
.modalWindowHeaderReturnLink {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
  padding-top: 36px;
  display: block;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background: url(../image/common/icon_arrow_left_white.svg) no-repeat 50% 38%;
  box-sizing: border-box;
}
/* 確認メッセージモーダル */
.modalRemindMessage .modalWindowBlock {
  width: 50%;
  min-width: 500px;
  max-width: 800px;
}
.modalRemindMessage .modalWindowContents {
  padding: 40px 24px;
}
.modalRemindMessageTitle {
  margin-bottom: 24px;
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.modalRemindMessagePara {
  margin-bottom: 24px;
  font-size: 14px;
  color: #333333;
  text-align: center;
}
.modalRemindMessageBtnBlock {
  margin: 0 auto;
  text-align: center;
}
.modalRemindMessageBtnBlock button {
  margin: 0 8px;
}
@media screen and (max-width: 768px) {
  .modalWindowBlock {
    width: 100%;
    min-width: unset !important;
    max-width: 100vw;
  }
  .modalWindowContents {
    box-sizing: border-box;
  }
}
/* チャットモーダル */
.modelChatWindow .modalWindowHeader {
  background: #ffffff;
  border-bottom: 1px solid #f0eeef;
}
.modelChatWindow .modalWindowHeaderTitle {
  color: #333333;
}
.modelChatWindow .modalWindowBlock {
  width: 50%;
  min-width: 600px;
  max-width: 800px;
  /*max-height: 70%;*/
}
.modelChatWindowTimelineBlock {
  padding: 24px 24px 100px;
  overflow-y: scroll;
  max-height: 60vh;
}
.modelChatWindowTimelineOrixMessageBlock {
  width: 60%;
}
.modelChatWindowTimelineOrixMessageInner {
  margin-top: 12px;
}
.modelChatWindowTimelineOrixMessagePara {
  padding: 16px;
  font-size: 14px;
  color: #333333;
  background: #eceeef;
  box-sizing: border-box;
  border-radius: 20px 20px 20px 0;
}

.modelChatWindowTimelineUserMessageBlock {
  width: 60%;
  margin: 0 0 0 auto;
}
.modelChatWindowTimelineUserMessageInner {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.modelChatWindowTimelineUserMessagePara {
  padding: 16px;
  font-size: 14px;
  color: #ffffff;
  background: #00b862;
  box-sizing: border-box;
  border-radius: 20px 20px 0 20px;
}
.modelChatWindowTimelineUserMessageImgPara {
}
.modelChatWindowTimelineOrixrMessageBlock .modelChatWindowTimelineTimestamp {
  text-align: left;
}
.modelChatWindowTimelineUserMessageBlock .modelChatWindowTimelineTimestamp {
  text-align: right;
}

.modelChatWindowTimelineTimestamp {
  margin-top: 4px;
  font-size: 11px;
  color: #8b8b8b;
  font-family: 'Arial';
}
.modelChatWindowTimelineReadPara {
  width: 16px;
  margin-right: 12px;
  font-size: 11px;
  color: #8b8b8b;
  word-break: keep-all;
}
.modelChatWindowMessageBlock {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 80px;
  padding: 24px;
  background: #ffffff;
  box-sizing: border-box;
  border-top: 1px solid #f0eeef;
  display: flex;
  justify-content: space-between;
}
.modelChatWindowMessageTextarea {
  width: 88%;
  height: 40px;
  max-width: 88%;
  min-height: 40px;
  max-height: 8em;
  padding: 8px;
  border: 1px solid #dfe1e3;
  font-size: 13px;
  border-radius: 3px;
  box-sizing: border-box;
}
.modelChatWindowMessageTempfileLink {
  position: absolute;
  right: 17%;
  display: block;
  width: 20px;
  height: 40px;
  background: url(../image/common/icon_clip_gray.svg) no-repeat center center;
}
.modelChatWindowMessageSubmit {
  width: 56px;
  height: 40px;
  background: #00b862;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border: none;
  border-radius: 3px;
}
/*サイトポリシー*/
.modelSitePolicy {
  background: #ffffff;
}
.modelSitePolicy .modalWindowHeaderTitle {
  background-color: #fff;
  color: #1c6ecd;
  padding-top: 24px;
}
.modelSitePolicy .modalWindowContents {
  padding-top: 16px;
}
.modelSitePolicy .modalWindowHeaderCloseLink {
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
  height: 64px;
  padding-top: 36px;
  display: block;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background: url(../image/common/icon_cross_white.svg) no-repeat 50% 38%;
  background-color: #1c6ecd;
  box-sizing: border-box;
}
.modelSitePolicys {
  height: 60vh;
  overflow: auto;
  margin: 16px 40px 40px;
  font-size: 14px;
}
.modelSitePolicys h2 {
  font-weight: bold;
  color: #1c6ecd;
}
.modelSitePolicys h3 {
  font-weight: bold;
}
.modelSitePolicys h4 {
  font-size: 12px;
  font-weight: bold;
}
.modelSitePolicyPara {
  color: #333333;
  font-size: 14px;
  white-space: pre-line;
  margin-bottom: 20px;
}
.modelSitePolicyPara a {
  color: #1c6ecd;
}
a.acrobatDownloadLink {
  display: block;
  margin: 8px 0;
  color: #1c6ecd;
}

/*造形可否判定結果*/
.modelAboutModelingPostureWindow .modalWindowBlock {
  width: 944px;
}
.aboutModelingPosture {
  padding: 32px 32px 0 32px;
  max-height: 80vh;
  overflow: auto;
}
.aboutModelingPosture img {
  margin-bottom: 16px;
}
.aboutModelingPostureTextBlock h2.aboutModelingPostureH2 {
  font-size: 16px;
  font-weight: bold;
  color: #1c6ecd;
  margin-bottom: 8px;
}
.aboutModelingPostureTextBlock p.aboutModelingPostureText {
  font-size: 14px;
  background-color: #e6eef8;
  padding: 16px;
  color: #333;
  margin-bottom: 16px;
}
.pageAboutModelingPostureTitle {
  margin-bottom: 32px;
}
.pageAboutModelingPostureImage img {
  margin-bottom: 32px;
}

.btnCloseGray {
  display: inline-block;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #8b8b8b;
  border-radius: 24px;
  background-color: #fff;
  border: 2px solid #8b8b8b;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  min-width: 160px;
  margin-top: 16px;
  margin-right: 16px;
}
.btnCloseGray:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  background-color: #8b8b8b;
  min-width: 160px;
}
/*戻るボタン*/
.btnBack,
.btnCancel {
  display: inline-block;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #8b8b8b;
  border-radius: 24px;
  background-color: #fff;
  border: 2px solid #8b8b8b;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  min-width: 160px;
  margin-top: 16px;
  margin-right: 16px;
}
.btnBack:hover,
.btnCancel:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  background-color: #8b8b8b;
  min-width: 160px;
}

/*上部固定フローバー無しの場合 
メインコンテンツ上部余白*/
.noFlowBar {
  margin-top: 106px;
}

.autoNumberingText {
  font-weight: bold;
}

.modalModelingPostureHeader {
  background-color: #e6eef8 !important;
  border-bottom: 2px solid #1c6ecd;
  margin: 0 !important;
}

.modalModelingPostureHeaderExp {
  padding-left: 1em;
  font-size: 16px;
  font-weight: bold;
  color: #ff8f22 !important;
  min-height: 0 !important;
}

.modalModelingPostureHeaderExp .MuiAccordionSummary-content {
  display: flex;
  align-items: center;
}

.modalModelingPostureHeaderExp.Mui-expanded {
  min-height: 0 !important;
}

.modalModelingPostureHeaderExp .MuiAccordionSummary-content {
  margin: 7px 0;
}

.modalModelingPostureHeaderExp .MuiAccordionSummary-content.Mui-expanded {
  margin: 7px 0 0;
}

.modalModelingPostureHeaderExpDetail {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 8px !important;
  color: #1c6ecd;
  font-size: 12px;
  white-space: pre-line;
}

.tableHeader th {
  border-bottom: 2px solid #1c6ecd;
  background: #e6eef8;
  font-weight: bold;
  word-break: keep-all;
}
.tableHeaderBorder th {
  border-right: 1px solid rgba(224, 224, 224, 1);
}
.tableHeaderNoWeight th {
  border-bottom: 2px solid #1c6ecd;
  background: #e6eef8;
}
.tableHeaderComment {
  font-size: 12px;
  padding-top: 4px;
}
.tableBodyBorder td {
  border-right: 1px solid rgba(224, 224, 224, 1);
}
.tableBodyBorderWordBreak td {
  word-break: keep-all;
  white-space: pre;
}
.tableRow:hover {
  background-color: #e6eef8;
}

.questionIcon {
  color: #ffffff;
  background-color: #1c6ecd;
  border-radius: 50%;
}

.modalCloseButton {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 58px;
  height: 58px;
  padding-top: 30px;
  display: block;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background: url(../image/common/icon_cross_white.svg) no-repeat 50% 38%;
  box-sizing: border-box;
  background-color: #1c6ecd;
  border-style: none;
}

.recommendFlash {
  animation: flash 1s linear infinite;
}
@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.customCheckBox input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 20px;
  vertical-align: -0.8rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin: 0.4rem;
  outline: none;
  border-radius: 10%;
}
/* Checkbox */
.customCheckBox input[type='checkbox']:before,
input[type='checkbox']:after {
  position: absolute;
  content: '';
  background: #fff;
}
.customCheckBox input[type='checkbox']:before {
  left: 2px;
  top: 6px;
  width: 0;
  height: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.customCheckBox input[type='checkbox']:after {
  right: 9px;
  bottom: 3px;
  width: 2px;
  height: 0;
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -o-transform: rotate(40deg);
}
.customCheckBox input[type='checkbox']:checked:before {
  left: 0px;
  top: 7px;
  width: 5.3px;
  height: 2px;
}
.customCheckBox input[type='checkbox']:checked:after {
  right: 3px;
  bottom: 0px;
  width: 2px;
  height: 11px;
}
.customCheckBox input[type='checkbox'].Orange {
  border: 4px solid #ff8f22;
  animation-name: blink;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
  animation-duration: 2s;
}
.customCheckBox input[type='checkbox'].Blue {
  border: 4px solid gray;
}
.customCheckBox input[type='checkbox'].Orange:checked {
  background: #ff8f22;
  animation-duration: 0s;
}
.customCheckBox input[type='checkbox'].Blue:checked {
  background: #007aff;
  border: 4px solid #007aff;
}
@keyframes blink {
  0% {
    border-color: gray;
  }
  50% {
    border-color: #ff8f22;
  }
  100% {
    border-color: gray;
  }
}
@keyframes Noblink {
  0% {
    border-color: transparent;
  }
}

.formHelpInfomationLinkBlink {
  animation-name: QuestionBlink;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}
@keyframes QuestionBlink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.modelingPostureImage {
  max-height: 100px;
  transition: 0.5s all;
}
.modelingPostureImage:hover {
  max-height: 350px;
  transition: 0.5s all;
}