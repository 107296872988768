@charset "UTF-8";
/* -------------------------------------
 * modelNumberSpecList.css
 * 型番仕様一覧ページ用
------------------------------------- */

/* -----------------------------
 * 一覧
 ------------------------------*/
 .modelNumberListTableBlock {
  width: 100%;
}
.modelNumberListTable {
  width: 100%;
  background: #fff;
  border-collapse: collapse;
}
.modelNumberListTable thead {
  border-bottom: 2px solid #1c6ecd;
  background: #e6eef8;
}
.modelNumberListTable tr {
  border-bottom: 1px solid #dfe1e3;
}
.modelNumberListTable tr:hover {
  background: #e6eef8;
}
.tableRowOrange {
  background: rgba(250, 142, 34, 0.25);
}
.tableRowOrange:hover {
  background: rgba(250, 142, 34, 0.4) !important;
}
.modelNumberListTable th{
  height: 42px;
  padding:0 8px;
  font-size: 13px;
  color:#333333;
  font-weight: bold;
  white-space: nowrap;
  text-align: left;
  border-left:1px solid #F5F5F6;
}
.modelNumberListTable td{
  padding: 12px 8px;
  font-size: 14px;
  color: #333333;
}
.modelNumberListSortLink{
  display: inline-block;
  width: 5px;
  height: 10px;
  margin-left: 8px;
}
.modelNumberListSortLinkDefalt{
  background: url(../image/common/icon_sort_defalt.svg) no-repeat top left;  
}
.modelNumberListSortLinkAscending{
  background: url(../image/common/icon_sort_ascending.svg) no-repeat top left;
}
.modelNumberListSortLinkDescending{
  background: url(../image/common/icon_sort_descending.svg) no-repeat top left;
}

.modelNumberListTableItemThumb{
  display: flex;
}
.modelNumberListTableItemThumbCheckbox{
  width: 16px;
  margin-right: 12px;
}
.modelNumberListTableItemThumbImage{
  width: 48px;
  height: auto;
}
.modelNumberListTableItemFilename{
  max-width: 6em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.modelNumberListTableItemLabelname{
  max-width: 6em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.modelNumberListTableItemModelname{
  max-width: 5em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.modelNumberListTableItemMaterial{
  max-width: 6em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.modelNumberListTableItemMachine{
  max-width: 5em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.modelNumberListTableItemPrice{
  font-size: 13px;
}
.modelNumberListTableItemDelivery{
  font-size: 13px;
}
.modelNumberListTableItemStatus{
  color: #333333;
}
.modelNumberListTableItemDate{
  font-size: 12px;
  color:#707070;
}
.modelNumberListTableItemToDetail{}
.modelNumberListTableItemCheckbtn{
  display: block;
  margin: 1px auto;
  padding: 4px 8px;
  font-size: 12px;
  color: #1C6ECD;
  text-align: center;
  text-decoration: none;
  border: 1px solid #1C6ECD;
  border-radius: 30px;
  white-space: nowrap;
  background: #ffffff;
  transition: all .5s ease-out;
}
.modelNumberListTableItemCheckbtn:hover{
  color: #ffffff;
  background: #1C6ECD;
}
.btnQuoteDownload,.btnTrialBalanceListDownload{
  display: inline-block;
  padding: 8px 20px 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #1C6ECD;
  border-radius: 24px;
  border: none;
  border: 2px solid #1C6ECD;
  box-sizing: border-box;
  background-color: #1C6ECD;
  background:#fff url("../image/common/icon_Download.svg") no-repeat 12% 50%;
  transition: all .5s ease-out;
  min-width: 204px;
}
.btnQuoteDownload:hover,.btnTrialBalanceListDownload:hover{
  color: #fff;
  background: url(../image/common/icon_Download_white.svg) no-repeat 12% 50%;
    background-color: rgba(0, 0, 0, 0);
  background-color: #1C6ECD;
  min-width: 204px;
}


.modelNumberListTableItemFilename {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.btnQuoteDownloadTd{
  width:15em;
}

.trialBalanceListTableItemLabelname {
  max-width: 16em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.btnQuoteDownload:disabled,.btnTrialBalanceListDownload:disabled{
  display: inline-block;
  padding: 8px 20px 8px 40px;
  height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #8B8B8B;
  border-radius: 24px;
  border: none;
  border: 2px solid #F0EEEF;
  box-sizing: border-box;
  background:#F0EEEF url("../image/common/icon_Download_gray.svg") no-repeat 12% 50%;
  transition: all .5s ease-out;
  min-width: 204px;
}
.bannerBlock {
  margin-top: -28px;
}